/*eslint-disable @typescript-eslint/explicit-function-return-type */

import { APP_INITIALIZER, inject, Injectable } from '@angular/core';
import { initShellApp } from 'shell-browser/app.js';
import {
  BuiltInResolvers,
  RegionImpl,
} from 'shell-browser/region.js';
import 'shell-browser/widget.js';

import { EnvConfigService } from '../services/env-config.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  envConfigService = inject(EnvConfigService);


  public async initApp(): Promise<void> {
    await this.envConfigService.Init();

    initShellApp({
      appId: this.envConfigService.config?.serviceName ?? '',
      env: this.envConfigService.config?.environmentName ?? '',
      appName: 'eService Boilerplate',
      region: {
        resolvers: [
          //custom resolver that is allowing the users to set the RegionImpl directly from the URL
          async () => {
            const url = location.pathname; 
 
            if(url === '/' || url === '/logout' || url === '/login') {
              return null //if there is no language params in the URL go to the next resolver
            } 

            //set RegionImpl from the URL
            const country = url?.split('/')[1] ; // ex: 'ch'
            const language = url?.split('/')[2]; // ex: 'en-us'

            if (country && language &&  language.split('-').length === 2 ) {
              const languageParts = language.split('-'); // ex: ['en', 'US']
              const locale = `${languageParts[0]}-${languageParts[1].toUpperCase()}`; // ex: 'en-US'

              return new RegionImpl({
                country,
                locale,
              });
            } else {
              return null // go to the next resolver
            }
          },

         // this resolver will only be called if the previous one returns null, 
         // example: when user selects a region that is not available get RegionImpl from cache
         // example: when there is no :country:language param get RegionImpl from cache
          BuiltInResolvers.cache, 
          
          // this resolver will only be called if the previous one returns null, 
          async () => {
             return new RegionImpl({
                country: 'ch',
                locale: 'en-US',
              });
           
          },
        ],
      },
    });
  }
}

export const appInitializerProvider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory:
    (appInitializerService: AppInitializerService): (() => Promise<void>) =>
    () =>
      appInitializerService.initApp(),
  deps: [AppInitializerService],
};
