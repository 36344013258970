import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainPageComponent } from './features/main-page/main-page.component';
import { ShellInfoPageComponent } from './features/shell-info-page/shell-info-page.component';

export const routes: Routes = [
  {
    path: ':country/:language',
    children: [
      {
        path: 'main',
        component: MainPageComponent,
      },
      {
        path: 'shell-info',
        component: ShellInfoPageComponent,
      },
      // when the shell redirects to [country]/[region], redirect by default to "main"
      {
        path: '**',
        redirectTo: 'main',
      },
    ],
  },

  // when a path is not found, redirect to "main"
  {
    path: '**',
    component: MainPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
