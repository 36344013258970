<ng-container *transloco="let t; read: 'shellInfo'">
  <owc-table
    [size]="'small'"
    [rounded]="true"
    [loading]="isAuthLoading$ | async"
    [showRowExpanderColumn]="true"
  >
    <owc-component-header slot="header" status="default">
      {{ t('shellData') }}
      <owc-icon
        slot="prefix"
        family="outlined"
        name="task"
        status="inherit"
      ></owc-icon>
    </owc-component-header>

    <owc-table-header [sticky]="true">
      <owc-table-header-cell
        [width]="'20%'"
        [align]="'left'"
        [resizable]="true"
        [sortable]="false"
        [sortOrder]="'asc'"
        [valign]="'middle'"
        >{{ t('property') }}</owc-table-header-cell
      >

      <owc-table-header-cell
        [width]="'auto'"
        [align]="'left'"
        [resizable]="true"
        [sortable]="false"
        [sortOrder]="'asc'"
        [valign]="'middle'"
      >
        {{ t('propertyValues') }}</owc-table-header-cell
      >
    </owc-table-header>

    <owc-table-body>
      <!--  current auth token -->
      <owc-table-row class="font-bold background-light-gray">
        <owc-table-cell>{{ t('tokenData') }}</owc-table-cell>
        <owc-table-cell> </owc-table-cell>
      </owc-table-row>

      @if (authToken$ | async; as authToken) {
        <owc-table-row>
          <owc-table-cell>{{ t('authToken') }}</owc-table-cell>
          <owc-table-cell class="string-break">{{ authToken }}</owc-table-cell>
        </owc-table-row>
      } @else {
        <owc-table-row>
          <owc-table-cell>{{ t('authToken') }}</owc-table-cell>
          <owc-table-cell class="font-warn">
            {{ t('availableAfterLogin') }}</owc-table-cell
          >
        </owc-table-row>
      }

      <!-- current logged in user data -->
      <owc-table-row class="font-bold background-light-gray">
        <owc-table-cell>{{ t('userData') }}</owc-table-cell>
        <owc-table-cell> </owc-table-cell>
      </owc-table-row>
      @if (userInfo$ | async; as userInfo) {
        <owc-table-row>
          <owc-table-cell>{{ t('name') }}</owc-table-cell>
          <owc-table-cell>{{ userInfo.name }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('familyName') }}</owc-table-cell>
          <owc-table-cell>{{ userInfo.familyName }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('fullName') }}</owc-table-cell>
          <owc-table-cell>{{ userInfo.fullName }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('email') }}</owc-table-cell>
          <owc-table-cell>{{ userInfo.email }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('country') }}</owc-table-cell>
          <owc-table-cell>{{ userInfo.country }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('language') }}</owc-table-cell>
          <owc-table-cell>{{ userInfo.language }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('defaultLocale') }}</owc-table-cell>
          <owc-table-cell>{{ userInfo.defaultLocale }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('isGenericAccount') }}</owc-table-cell>
          <owc-table-cell>{{ userInfo.genericAccount }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('primaryAccount') }}</owc-table-cell>
          <owc-table-cell>{{ userInfo.primaryAccount }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('UUID') }}</owc-table-cell>
          <owc-table-cell>{{ userInfo.uuid }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('trackingID') }}</owc-table-cell>
          <owc-table-cell>{{ userInfo.trackingId }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('passwordExpirationDate') }}</owc-table-cell>
          <owc-table-cell>{{
            userInfo.passwordExpirationDate || '---'
          }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('roles') }}</owc-table-cell>
          <owc-table-cell>{{
            userInfo.roles?.join(', ') || '---'
          }}</owc-table-cell>
        </owc-table-row>
      } @else {
        <owc-table-row>
          <owc-table-cell>{{ t('user') }}</owc-table-cell>
          <owc-table-cell class="font-warn">
            {{ t('availableAfterLogin') }}</owc-table-cell
          >
        </owc-table-row>
      }

      <!--  current region -->
      @if (activeRegion$ | async; as activeRegion) {
        <owc-table-row class="font-bold background-light-gray">
          <owc-table-cell>{{ t('regionData') }}</owc-table-cell>
          <owc-table-cell> </owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('country') }}</owc-table-cell>
          <owc-table-cell>{{ activeRegion.country }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('language') }}</owc-table-cell>
          <owc-table-cell>{{ activeRegion.language }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('dialect') }}</owc-table-cell>
          <owc-table-cell>{{ activeRegion.dialect }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row>
          <owc-table-cell>{{ t('IETF') }}</owc-table-cell>
          <owc-table-cell>{{ activeRegion.ietf }}</owc-table-cell>
        </owc-table-row>

        <owc-table-row class="font-bold background-light-gray">
          <owc-table-cell>{{ t('availableLanguages') }}</owc-table-cell>
          <owc-table-cell> </owc-table-cell>
        </owc-table-row>

        @for (
          language of availableLanguagesForCurrentRegion$ | async;
          track language.language
        ) {
          <owc-table-row>
            <owc-table-cell>{{ language.description }}</owc-table-cell>
            <owc-table-cell>{{ language.language }}</owc-table-cell>
          </owc-table-row>
        }
      }

      <!-- cookies accepted by the user, C0001 is the mandatory cookie and is present from the beggining -->
      <owc-table-row class="font-bold background-light-gray">
        <owc-table-cell>{{ t('cookieData') }}</owc-table-cell>
        <owc-table-cell> </owc-table-cell>
      </owc-table-row>
      <owc-table-row>
        <owc-table-cell>{{ t('acceptedCookies') }}</owc-table-cell>
        <owc-table-cell>{{ acceptedCookies$ | async | json }}</owc-table-cell>
      </owc-table-row>
    </owc-table-body>
  </owc-table>
</ng-container>
