import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import type { ShellObservable } from 'shell-browser/reactivity.js';

/**
 * Service to wrap subscriptions to shell observables so angular is notified and triggers
 * the change detection
 */
@Injectable({ providedIn: 'root' })
export class NgShellRxjsWrapperService {
  constructor(protected readonly ngZone: NgZone) {}
  /**
   * Convert a shell observable into a RxJs observable and notify angular on changes
   * @param shellCallback
   */
  toObservable<T = void>(shellCallback: ShellObservable<T>): Observable<T> {
    // create an observable to track the value from the shell observable
    return new Observable<T>(subscriber => {
      // subscribe to the shell observable and pass down the value to a rxjs observable
      const shellSubscription = shellCallback((value: T) => {
        // using ngZone.run we notify the change to angular
        this.ngZone.run(() =>
          // pass down the value to the rxjs observable
          subscriber.next(value),
        );
      });
      // return the unsubscribe mechanism from the shell, so when rxjs executes
      // the unsubscribe of the observable, the shell is also unsubscribed
      return () => shellSubscription.unsubscribe();
    });
  }
}
