<shell-root>
  <shell-header slot="header" *transloco="let t; read: 'header'">
    <!-- desktop view logo -->
    <a slot="logo">
      <shell-app-logo>
        <img
          slot="icon"
          alt="Icon placeholder"
          style="width: 30px"
          src="assets/eService_logo.svg"
        />
        <span one-topbar-title>App Enabler App</span>
      </shell-app-logo>
    </a>

    <!-- mobile view logo -->
    <a slot="mobile-logo">
      <shell-app-logo hide-content>
        <img
          slot="icon"
          alt="Icon placeholder"
          src="assets/eService_logo.svg"
        />
      </shell-app-logo>
    </a>

    <!-- Optional actions presented as icons in the header -->
    <!-- Mobile view actions to be defined in shell-sidenav -->
    <shell-actions slot="actions"></shell-actions>

    <!-- Common navbar (desktop & mobile) -->
    <shell-nav-menu slot="nav-menu">
      <shell-nav-menu-item
        [routerLink]="getRouterLink('main')"
        routerLinkActive
        #rla1="routerLinkActive"
        [attr.active]="rla1.isActive || null"
      >
        {{ t('mainPage') }}
      </shell-nav-menu-item>
      <shell-nav-menu-item
        [routerLink]="getRouterLink('shell-info')"
        routerLinkActive
        #rla2="routerLinkActive"
        [attr.active]="rla2.isActive || null"
      >
        {{ t('shellInfo') }}
      </shell-nav-menu-item>
    </shell-nav-menu>
  </shell-header>

  <!-- Router with app body -->
  <router-outlet></router-outlet>

  <shell-footer slot="footer"></shell-footer>

  <!-- side panel shown on the left in mobile -->
  <shell-sidenav slot="sidenav">
    <a slot="logo">
      <shell-app-logo>
        <span>App Enabler App</span>
      </shell-app-logo>
    </a>
    <!-- optional action to be displayed in the side nav 
      other action include login/logout and lanugage change -->
    <shell-actions slot="actions"></shell-actions>
  </shell-sidenav>
</shell-root>
